.Container {
    .video-wrapper {
        width: 90%;
        /* height: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        position: relative;

        #video {
            width: 100%;
        }
    }

    .controls-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        left: 50%;
        position: absolute;
        /* top: 50%; */
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        transition: all .3s;
        width: 60vw;
    }

    img {
        cursor: pointer;
        filter: contrast(0.5);
        /* transition: .3s all; */
        width: 60px;
        height: 60px;
    }

    img:hover {
        height: 80px;
        width: 80px;
    }


    @media only screen and (max-width: 600px) {
        img {
            width: 30px;
            height: 30px;
        }

        img:hover {
            height: 50px;
            width: 50px;
        }
    }


    .controls {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        left: 5%;
        right: 5%;
        width: 90%;
    }

    .bottom-player-control {
        background: rgba(0, 0, 0, .3);
        /* bottom: 40px; */
        height: 23px;
        /* left: 20px; */
        position: absolute;
        /* right: 20px; */
        transition: all .3s;
        width: calc(100% - 40px);
        width: 100%;
        padding-top: 6px;
        display: flex;
        gap: 10px;

        .ui-video-seek-slider {
            width: 100%;
        }

        p {
            color: white;
            margin: 0px;
            margin-top: -3px;
        }

        .seeker-control {
            margin-left: -5px;
        }

        .total-duration-control {
            margin-right: 10px;
            width: 20px;
            filter: none;
            height: auto;
            margin-top: -6px;


        }

        .total-duration-control:hover {
            filter: hue-rotate(159deg);
        }
    }

    .ui-time-seek-slider .thumb .handler {
        background-color: #0296ff !important;
    }

    .track .main .connect {
        background-color: #0296ff !important;
    }

    .difficulty-container {
        width: 11px;
        height: 11px;
        background-color: red;
        border-radius: 5px;
        margin-top: 3px;
        margin-left: 3px;
    }
}