.wrapper {
  .hasProfileUploadWrapper {
    display: flex;
    margin-bottom: 24px;

    .profilePhotoWrapper {
      display: flex;
      gap: 20px;
      margin-top: 20px;

      // padding: 0px 20px;
      // align-items: center;
      // flex-direction: column;
      .hasImage {
        img {
          width: 400px;
          height: 200px;
          position: relative;
          object-fit: contain;
          object-position: left;
        }

      }

      .hasUploadButton {
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;

        label {
          width: fit-content;
          border: 1px solid currentColor;
          display: block;
          letter-spacing: .6px;
          text-align: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          background: #fe6d02;
          color: #ffff;
          border-radius: 2px;
          padding: 6px 12px;
          cursor: pointer;
          transition: all .3s;
          border-radius: 2px;
          text-transform: uppercase;

          & :hover {
            background: #1F87C0;
            color: #fff;
            border-color: #1F87C0;
          }

        }

        p {
          font-size: 14px;
          color: red;
        }

        input {
          opacity: 0;
          // visibility: hidden;
          position: absolute;
          z-index: 9999;
        }
      }
    }

  }

  &__video {
    display: flex;

    &__input {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 0;
      border: 1px solid rgba(0, 0, 0, 0.3);
      height: 650px;
      margin-right: 30px;
      overflow: hidden;


      &__preview {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      input {
        display: none;
      }
    }

    &__settings {
      position: relative;
      min-width: 400px;
      flex: 0 0 35vw;

      &__equipment {
        z-index: 10;
      }

      &__coach {
        z-index: 9;
      }

      &__body_part {
        z-index: 8;
      }

      &__workout_style {
        z-index: 7;
      }

      &__prioritize_watch_style {
        z-index: 6;
      }

      &__difficulty,
      &__calories,
      &__hr {
        label {
          display: block;
        }

        input {
          width: 100%;
        }
      }

      &__equipment,
      &__coach,
      &__body_part,
      &__workout_style,
      &__title,
      &__prioritize_watch_style {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
      }

      &__coach_2 {
        position: relative;
        width: 100%;
        margin-bottom: 0px;
      }
    }
  }

  &__parts {
    margin-top: 30px;

    &__tile {
      input {
        width: 100%;
      }

      &__header {
        display: flex;
        align-items: center;
        column-gap: 20px;

        button {
          white-space: nowrap;
        }

      }

      &__part {
        th {
          color: white;
          padding: 5px;
          text-align: center;
        }

        &__nodes {
          &:hover {
            background: white;

            td {
              color: black !important;
            }
          }
        }
      }
    }
  }

  &__submit {
    margin-top: 30px;
  }
}

.dialog {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    margin: auto;
    background: white;
    border-bottom: 2px solid #fe6d02;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    padding: 20px;

    small {
      color: rgba(0, 0, 0, 0.7);
      font-size: 10px;
      text-align: center;
      display: block;
    }

    &__header {
      display: flex;
      align-items: center;

      span {
        width: 120px;
        display: block;
        height: 1px;
        background: #fe6d02;
      }

      p {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #fe6d02;

        &[data-current-step] {
          background: #fe6d02;
          color: white;
        }
      }
    }

    &__progress {
      margin-top: 20px;
      text-align: center;

      &__first {
        margin-bottom: 20px;

        &:after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          animation: ellipsis steps(4, end) 900ms infinite;
          content: "\2026";
          width: 0;
        }
      }

      &__bar {
        border: 2px solid #fe6d02;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 0;
        position: relative;

        span {
          position: absolute;
          left: 0;
          top: 0;
          background: #fe6d02;
          height: 100%;
          display: block;
        }

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

@keyframes ellipsis {
  to {
    width: 24px;
  }
}