.multiselect {
  position: relative;
  width: 300px;
  z-index: 0;
  padding: 5px 30px 5px 40px;
  border-bottom: 1px solid #fe6d02;
  border-top: none;
  border-right: none;
  border-left: none;
  color: #252422;
  min-height: 30px;

  &_icon {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 20px;
    transform: translate(0, -50%);
  }

  &_placeholder {
    left: 40px;
    opacity: .7;
    pointer-events: none;
    top: 50%;
    transform: translate(0, -50%);
  }

  &_placeholder,
  &__menu {
    position: absolute;
  }

  &__menu,
  &__selected {
    background: white;
    width: 100%;
  }

  &__selected {
    min-height: inherit;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;

    &_option {
      background: #d0d0d0;
      padding: 3px 5px;
      display: flex;
      align-items: center;

      img {
        height: 15px;
      }
    }
  }

  &__menu {
    left: 50%;
    transform: translate(-50%, 0);
    width: 99%;
    top: 110%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    list-style-type: none;
    max-height: 200px !important;
    overflow: auto;

    &_option {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background: #252422;
        color: #FFFCF2;
      }
    }
  }
}