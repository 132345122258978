.workout {
  &__paragraph {
    border-bottom: 1px solid #2a2a2a;
    width: 100%;
    display: flex;
    column-gap: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    span {
      color: #31302e;
    }
  }

  &__difficultyParagraph {
    align-items: center;
  }

  &__difficultyResetButton {
    margin-left: auto;
  }

  &__player {
    margin-top: 20px;
    width: 100%;

    iframe {
      background: #252422;
      width: 100%;
      height: 480px;
    }

    &__parts {
      th {
        color: white;
        padding: 5px;
        text-align: center;
      }

      &__node {
        td {
          text-align: center;

          &:first-of-type {
            text-align: start;
          }
        }

        &:hover {
          background: white;

          td {
            color: black !important;
          }
        }
      }
    }



  }
}

.hasProfileUploadWrapper {
  .profilePhotoWrapper {
    .hasImage {
      .uploadImg {
        display: inline-block;
        width: 250px !important;
        height: 350px !important;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.hasUploadButton {
  margin: 30px 10px 25px 10px;
  position: relative;

  input {
    // display: none;
    opacity: 0;
    position: absolute;
    left: 0px;
  }

  label {
    background: #fe6d02;
    border: none;
    outline: none;
    cursor: pointer;
    color: white !important;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 20px;

  button {
    border-radius: 8px;
  }
}

.dialog {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    margin: auto;
    background: white;
    border-bottom: 2px solid #fe6d02;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    padding: 20px;

    small {
      color: rgba(0, 0, 0, 0.7);
      font-size: 10px;
      text-align: center;
      display: block;
    }

    &__header {
      display: flex;
      align-items: center;

      span {
        width: 120px;
        display: block;
        height: 1px;
        background: #fe6d02;
      }

      p {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid #fe6d02;

        &[data-current-step] {
          background: #fe6d02;
          color: white;
        }
      }
    }

    &__progress {
      margin-top: 20px;
      text-align: center;

      &__first {
        margin-bottom: 20px;

        &:after {
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          animation: ellipsis steps(4, end) 900ms infinite;
          content: "\2026";
          width: 0;
        }
      }

      &__bar {
        border: 2px solid #fe6d02;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 0;
        position: relative;

        span {
          position: absolute;
          left: 0;
          top: 0;
          background: #fe6d02;
          height: 100%;
          display: block;
        }

        p {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}